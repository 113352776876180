import { Component } from '@angular/core';
import {WhitelabelService} from "../../services/whitelabel.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  disclaimer = this.whitelabelService.disclaimer

  constructor(
    private whitelabelService: WhitelabelService,
  ) {}
}
