import {Component, effect, Input, Signal, signal, ViewChild} from '@angular/core';
import {QuestionService} from "../../services/question.service";
import {UserPromptResponse} from "../../interfaces/user-prompt-response";
import {UserPromptRequest} from "../../interfaces/user-prompt-request";
import {WhitelabelService} from "../../services/whitelabel.service";
import MarkdownIt from 'markdown-it';
import DOMPurify from "dompurify";



@Component({
  selector: 'answer-display',
  templateUrl: 'answer-display.component.html',
  styleUrls: ['answer-display.component.scss']
})
export class AnswerDisplayComponent {

  language = localStorage.getItem("userLanguage")
  iconSource = this.whitelabelService.logoUrl
  displayProgressBar: boolean = true;
  formattedResponse : string | Promise<string> = ""

  progressInterval = -1;
  progressBarValue = 50;
  progressBarMode: 'determinate' | 'query' = 'determinate';

  apiResponseSignal: UserPromptResponse = {
    client_request_uuid: "",
    server_request_uuid: "",
    prompt: "",
    answer: "",
    document_references: [],
    search_only: false,
    is_completed: false,
    limit_reached: false
  };

  promptRequest: UserPromptRequest | undefined = undefined

  @Input() rating: any;
  @Input() setRating!: (newRating : number) => void;

  displayedColumns: string[] = ['entry'];
  sourcesTableHeader: string = "";

  expanded = true;

  constructor(
    private questionService : QuestionService,
    private whitelabelService: WhitelabelService
    ) {
    effect(() =>{ //update signals from questionService when their values change
      this.promptRequest = this.questionService.promptRequestSignal();
    })
    effect(() =>{ //update signals from questionService when their values change
      this.displayProgressBar = this.questionService.displayProgressBar();
      if (this.displayProgressBar) {
        this.startProgress()
      } else {
        this.stopProgress()
      }
    })
    effect(() =>{ //update signals from questionService when their values change
      this.apiResponseSignal = this.processServerResponse(this.questionService.apiResponseSignal());
      this.sourcesTableHeader = this.apiResponseSignal.search_only ? $localize`Suchresultate` : $localize`Quellen`
    })
  }

  startProgress() {
    this.progressBarMode = 'determinate';
    this.progressBarValue = 0;
    const approxPromptProgressTime = 6000;
    const approxPromptProgressInterval = approxPromptProgressTime / 100
    this.progressInterval = setInterval(() => {
      this.progressBarValue += 1
      if (this.progressBarValue >= 100) {
        this.progressBarMode = 'query';
      }
    }, approxPromptProgressInterval)

  }

  stopProgress() {
    this.progressBarMode = 'determinate';
    this.progressBarValue = 0;
    clearInterval(this.progressInterval)
  }

  processServerResponse(serverResponse: UserPromptResponse) : UserPromptResponse {

    if (serverResponse.answer) {
      this.formattedResponse = this.parseResponse(serverResponse.answer)
    }

    for (let documentReference of serverResponse.document_references) {

      // cleanup white spaces
      if (documentReference.title === " ") documentReference.title = ""
      if (documentReference.subject === " ") documentReference.subject = ""
      if (documentReference.filename === " ") documentReference.filename = ""

      // format date
      if (documentReference.date) {
        documentReference.date = new Date(documentReference.date).toLocaleDateString()
      }

      if (documentReference.link && !documentReference.filename) {
        let lastPathComponent =  new URL(documentReference.link).pathname.split("/").pop();
        if (lastPathComponent != null) {
          documentReference.filename = lastPathComponent;
        }
      }

      documentReference.filename = decodeURIComponent(documentReference.filename)
      let filenameReadabilityScore = this.readabilityScore(documentReference.filename)

      let titleToDisplaReadabilityScore = this.readabilityScore(documentReference.title_to_display)

      if (!documentReference.title_to_display || titleToDisplaReadabilityScore < 0.5) {
        if (documentReference.filename && filenameReadabilityScore > 0.5) {
          documentReference.title_to_display = documentReference.filename;
        } else if (documentReference.title && documentReference.subject) {
          documentReference.title_to_display = `${documentReference.subject} - ${documentReference.title}`;
        } else if (documentReference.title) {
          documentReference.title_to_display = documentReference.title;
        } else if (documentReference.subject) {
          documentReference.title_to_display = documentReference.subject;
        } else if (documentReference.filename) {
          documentReference.title_to_display = documentReference.filename;
        }
      }

      this.whitelabelService.setTitleToDisplayForWhitelabel(documentReference)
    }
    return serverResponse
  }

  private readabilityScore(text: string) {
    if (!text) return 0

    const regex = /[a-zA-Z]/g;
    let match = text.match(regex)
    let numberOfCharacters = match ? match.length : 0
    let score = text.length < 8 ? 0 : 1 / text.length * numberOfCharacters
    return score
  }

  parseResponse(answer: string) {


    // convert to html automatically and sanitize that html
    const md = MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
    })

    let sanitizedHtml = DOMPurify.sanitize(md.render(answer));

    //find and replace all occurrences of phone numbers, unlike links and email addresses, they are not recognized by marked.js
    let phone_regex = /\s(\+?(\d|\d\s){10,11})/gi
    sanitizedHtml = sanitizedHtml.replaceAll(phone_regex, " <a target=\"_blank\" href=\"tel:$1\">$1</a>")

    //find all occurrences of links and replace them with links that open in a new tab
    sanitizedHtml = sanitizedHtml.replaceAll(/(<a href=".*?")/g, '$1 target="_blank" rel="noopener noreferrer"');


    // newline replace
    let newlineRegex = /(<br>)/gi
    if (this.promptRequest?.easy_language) {
      answer = answer.replace(newlineRegex, "<br><br>")
    } /*else {
      answer = answer.replace(newlineRegex, "<br>")
    }*/

    return sanitizedHtml;
  }

  get expandedClass(): string {
    return this.expanded ? "expanded" : ""
  }

  toggleExpand() {
    this.expanded = !this.expanded
  }

}
