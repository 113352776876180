import { Injectable } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {DialogData, ErrorDialogComponent} from "../components/error-dialog/error-dialog.component";
import * as Sentry from "@sentry/browser";

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(public dialog: MatDialog) {

  }

  /**
   * Show an error to the user. The error is also logged.
   * @param errorMessage
   * @param error
   */
  public showError(errorMessage: string, error: Error | undefined) {
    this.logError(error ?? Error(errorMessage))
    this.openErrorDialog(errorMessage, error)
  }

  /**
   * Log an error (invisible to the user)
   * @param error
   */
  public logError(error: Error) {
    console.error(error);
    Sentry.captureException(error)
  }

  public openErrorDialog(errorMessage: string, error: Error | undefined) {
    const errorData: DialogData = {
      error: error,
      errorMessage: errorMessage,
      errorType: undefined
    }
    this.dialog.open(ErrorDialogComponent, {
      data: errorData,
    });
  }
}
