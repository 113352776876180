<div class="answer-display-container">

  <div *ngIf="displayProgressBar" class="progress-bar-container">
    <mat-progress-bar [mode]="progressBarMode" color="accent" [value]="progressBarValue"></mat-progress-bar>
  </div>
  <div *ngIf="!apiResponseSignal.search_only" class="text-answer-container" >
    <div *ngIf="apiResponseSignal.answer && !displayProgressBar" class="logo-and-received-answer-container">
      <div class="icon-container">
        <img
          class="icon"
          width="40"
          alt="SHAU Logo"
          [src]="iconSource"
          i18n-alt
        />
      </div>
      <div class="received-answer-container">
        <div class="received-answer">
          <p class="formatted-response" [innerHTML]="formattedResponse"></p>
        </div>
      </div>
    </div>

    <feedback [apiResponseSignal]="apiResponseSignal" [rating]="rating" [setRating]="setRating"></feedback>
  </div>
  <div *ngIf="apiResponseSignal.answer && !displayProgressBar && apiResponseSignal.is_completed" class="received-sources-container">
    <mat-table class="received-sources" [dataSource]="apiResponseSignal.document_references">

      <ng-container matColumnDef="entry">
        <mat-header-cell *matHeaderCellDef class="sources-header-cell">
          <p class="mat-source-header" >{{sourcesTableHeader}}</p>
          <button mat-icon-button (click)="toggleExpand()">
            <mat-icon>
              <ng-container *ngIf="expanded">
                unfold_more
              </ng-container>
              <ng-container *ngIf="!expanded">
                unfold_less
              </ng-container>
            </mat-icon>
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" >
          <div class="document-details">
            <h3 [matTooltip]=element.title_to_display class="filename"><a href="{{element.link}}"  target="_blank">{{ element.title_to_display}}</a></h3>
            <p class="meta-info" i18n>
              <a href="{{ element.link }}" target="_blank">{{ element.link }}</a>
              <br>
              <span *ngIf="element.author">Autor/in: {{ element.author }} </span>
              <ng-container *ngIf="element.page_number && element.page_number > 1" >Seite: {{element.page_number}}</ng-container>
              <ng-container *ngIf="element.source_entity && element.source_entity != ''" ><br>Quelle: {{element.source_entity}}</ng-container>
            </p>

            <br>
            <p class="preview">
              <span [class]="expandedClass">
                <span *ngIf="element.date"><b>{{ element.date }}</b> – </span>{{ element.preview }}
              </span>
            </p>
          </div>
        </mat-cell>
        <mat-footer-cell></mat-footer-cell>

      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      <ng-container matColumnDef="noRecord">
          <mat-footer-cell  mat-footer-cell *matFooterCellDef i18n>
            Keine passenden Quellen gefunden.
          </mat-footer-cell>
      </ng-container>

      <ng-template [ngIf]="apiResponseSignal.document_references.length === 0 && apiResponseSignal.is_completed">
        <mat-footer-row *matFooterRowDef="['noRecord']" class="mat-footer" ></mat-footer-row>
      </ng-template>

    </mat-table>

    </div>
  </div>


