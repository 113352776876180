<div class="fine-print-container">

    <div class = info-accordion>
    <mat-accordion>
      <h2 i18n>Über uns</h2>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title i18n>
            Impressum
          </mat-panel-title>
        </mat-expansion-panel-header>
        <h4> Postanschrift</h4>

        <p>
          App Manufacture GmbH<br>Vordergasse 34<br>CH-8200 Schaffhausen<br>Schweiz
        </p>
        <p>
          Company number: CHE-400.461.751
        </p>

        <mat-divider></mat-divider>

        <h4> Haftungsausschluss</h4>

        <p>
          App Manufacture GmbH übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen.
          Haftungsansprüche gegen App Manufacture GmbH wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch
          der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen. Alle Angebote sind unverbindlich.
          Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
        </p>

        <mat-divider></mat-divider>

        <h4> Haftung für Links</h4>

        <p>
          Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Es wird jegliche Verantwortung für solche Webseiten abgelehnt.
          Der Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin.
        </p>

      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title i18n>
            Datenschutz
          </mat-panel-title>
        </mat-expansion-panel-header>

        <p><strong>Last updated March 03, 2024 </strong></p>
        <p>This privacy notice for App Manufacture GmbH (&#39;<strong>we</strong>&#39;, &#39;<strong>us</strong>&#39;, or &#39;<strong>our</strong>&#39;), describes how and why we might collect, store, use, and/or share (&#39;<strong>process</strong>&#39;) your information when you use our services (&#39;<strong>Services</strong>&#39;), such as when you: </p>
        <p>Visit our website at shau.ch, or any website of ours that links to this privacy notice </p>
        <p>Engage with us in other related ways, including any sales, marketing, or events </p>
        <p><strong>Questions or concerns? </strong>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at mail@appmanufacture.ch. </p>


        <p><strong>SUMMARY OF KEY POINTS </strong></p>
        <p><em>This summary provides key points from our privacy notice, but you can find out more details about any of these topics </em><em>by reviewing the privacy notice in full</em><em>. </em></p>
        <p><strong>What personal information do we process? </strong>When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us. </p>
        <p><strong>Do we process any sensitive personal information? </strong>We do not process sensitive personal information. <strong>Do we receive any information from third parties? </strong>We do not receive any information from third parties. </p>
        <p><strong>How do we process your information? </strong>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information. </p>
        <p><strong>In what situations and with which parties do we share personal information? </strong>We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information. </p>
        <p><strong>How do we keep your information safe? </strong>We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe. </p>
        <p><strong>What are your rights? </strong>Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights. </p>
        <p><strong>How do you exercise your rights? </strong>The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws. </p>
        <p>Want to learn more about what we do with any information we collect? Review the privacy notice in full. </p>


        <p><strong>1. WHAT INFORMATION DO WE COLLECT? </strong><strong>Personal information you disclose to </strong><strong>us</strong></p>
        <p><em>In Short: </em><em>We collect personal information that you provide to us. </em></p>
        <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us. </p>
        <p><strong>Personal Information Provided by You. </strong>The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following: </p>
        <p>email addresses </p>
        <p>passwords</p>
        <p><br />
          <strong>Sensitive Information. </strong>We do not process sensitive information. </p>
        <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information. </p>
        <p><strong>Information automatically </strong><strong>collected</strong></p>
        <p><em>In Short: </em><em>Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device characteristics &mdash; is collected automatically when you visit our Services. </em></p>
        <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes. </p>
        <p>Like many businesses, we also collect information through cookies and similar technologies. The information we collect includes: </p>
        <p><em>Log and Usage Data. </em>Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called &#39;crash dumps&#39;), and hardware settings). </p>


        <p><strong>2. HOW DO WE PROCESS YOUR INFORMATION? </strong></p>
        <p><em>In Short: </em><em>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. </em></p>
        <p><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including: </strong></p>
        <p><strong>To facilitate account creation and authentication and otherwise manage user accounts. </strong>We may process your information so you can create and log in to your account, as well as keep your account in working order. </p>
        <p><strong>To deliver and facilitate delivery of services to the user. </strong>We may process your information to provide you with the requested service. </p>
        <p><strong>To send administrative information to you. </strong>We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information. </p>
        <p><strong>To request feedback. </strong>We may process your information when necessary to request feedback and to contact you about your use of our Services. </p>
        <p><strong>To protect our Services. </strong>We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention. </p>
        <p><strong>To identify usage trends. </strong>We may process information about how you use our Services to better understand how they are being used so we can improve them. </p>
        <p><strong>To save or protect an individual&#39;s vital interest. </strong>We may process your information when necessary to save or protect an individual&rsquo;s vital interest, such as to prevent harm. </p>


        <p><strong>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION? </strong></p>
        <p><em>In Short: </em><em>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to </em><em>enter into</em><em> or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests. </em></p>
        <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information: </p>
        <p><strong>Consent. </strong>We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about withdrawing your consent. </p>
        <p><strong>Performance of a Contract. </strong>We may process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you. </p>
        <p><strong>Legitimate Interests. </strong>We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to: </p>
        <p>Analyse how our Services are used so we can improve them to engage and retain users Diagnose problems and/or prevent fraudulent activities<br />
          Understand how our users use our products and services so we can improve user experience </p>
        <p><strong>Legal Obligations. </strong>We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved. </p>
        <p><strong>Vital Interests. </strong>We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person. </p>


        <p><strong>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? </strong></p>
        <p><em>In Short: </em><em>We may share information in specific situations described in this section and/or with the following third parties. </em></p>
        <p><strong>Vendors, Consultants, and Other Third-Party Service Providers. </strong>We may share your data with third-party vendors, service providers, contractors, or agents (&#39;<strong>third parties</strong>&#39;) who perform services for us or on our behalf and require access to such information to do that work. We have contracts in place with our third parties, which </p>
        <p>are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organisation apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct. The third parties we may share personal information with are as follows: </p>
        <p><strong>Hosting </strong></p>
        <p>Amazon Web Services (AWS) </p>
        <p><strong>User Feedback and Error Logging </strong></p>
        <p>Sentry </p>
        <p><strong>Large Language Model (LLM) </strong></p>
        <p>Azure OpenAI </p>
        <p><strong>Content Storage </strong></p>
        <p>Pinecone<br />
          We also may need to share your personal information in the following situations: </p>
        <p><strong>Business Transfers. </strong>We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. </p>
        <p><strong>When we use Google Analytics. </strong>We may share your information with Google Analytics to track and analyze the use of the Services. The Google Analytics Advertising Features that we may use<br />
          include: Google Analytics Demographics and Interests Reporting. To opt out of being tracked by Google Analytics across the Services, visit https://tools.google.com/dlpage/gaoptout. You can opt out of Google Analytics Advertising Features through Ads Settings and Ad Settings for mobile apps. Other opt out means include http://optout.networkadvertising.org/ and http://www.networkadvertising.org/mobile-choice. For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms page. </p>


        <p><strong>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? </strong></p>
        <p><em>In Short: </em><em>We may use cookies and other tracking technologies to collect and store your information. </em></p>
        <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice. </p>


        <p><strong>6. HOW LONG DO WE KEEP YOUR INFORMATION? </strong></p>
        <p><em>In Short: </em><em>We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law. </em></p>
        <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal </p>
        <p>requirements). No purpose in this notice will require us keeping your personal information for longer than three (3) months past the termination of the user&#39;s account. </p>
        <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible. </p>


        <p><strong>7. HOW DO WE KEEP YOUR INFORMATION SAFE? </strong></p>
        <p><em>In Short: </em><em>We aim to protect your personal information through a system of </em><em>organisational</em><em> and technical security measures. </em></p>
        <p>We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or </p>
        <p>other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment. </p>


        <p><strong>8. WHAT ARE YOUR PRIVACY RIGHTS? </strong></p>
        <p><em>In Short: </em><em>In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Switzerland, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time. </em></p>
        <p>In some regions (like the EEA, UK, and Switzerland), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section &#39;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&#39; below. </p>
        <p>We will consider and act upon any request in accordance with applicable data protection laws.<br />
          If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you </p>
        <p>also have the right to complain to your Member State data protection authority or UK data protection authority. </p>
        <p>If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner. </p>
        <p><strong>Withdrawing your consent: </strong>If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section &#39;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&#39; below. </p>
        <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent. </p>
        <p><strong>Account Information </strong></p>
        <p>If you would at any time like to review or change the information in your account or terminate your account, you can: </p>
        <p>Log in to your account settings and update your user account. </p>
        <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements. </p>
        <p><strong>Cookies and similar technologies: </strong>Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. </p>
        <p>If you have questions or comments about your privacy rights, you may email us at mail@appmanufacture.ch. </p>


        <p><strong>9. CONTROLS FOR DO-NOT-TRACK FEATURES </strong></p>
        <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (&#39;DNT&#39;) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice. </p>


        <p><strong>10. DO WE MAKE UPDATES TO THIS NOTICE? </strong></p>
        <p><em>In Short: </em><em>Yes, we will update this notice as necessary to stay compliant with relevant laws. </em></p>
        <p>We may update this privacy notice from time to time. The updated version will be indicated by an<br />
          updated &#39;Revised&#39; date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information. </p>


        <p><strong>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </strong></p>
        <p>If you have questions or comments about this notice, you may email us at mail@appmanufacture.ch</p>


      </mat-expansion-panel>
    </mat-accordion>
    </div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
</div>
