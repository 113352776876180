import { Injectable } from '@angular/core';
import {Whitelabel} from "./whitelabel.enum";
import {environment} from 'src/environments/environment';
import {DocumentReference} from "../interfaces/documentReference";
import {gptVersion} from "../interfaces/gptVersions";


@Injectable({
  providedIn: 'root'
})
export class WhitelabelService {

  constructor() { }

  get whiteLabelVariant(): Whitelabel {
    if (environment.whitelabel == Whitelabel.BIBLIO_SH) {
      return Whitelabel.BIBLIO_SH;
    } else {
      return Whitelabel.SHAU;
    }
  }

  get logoUrl(): string {
    if (this.whiteLabelVariant == Whitelabel.BIBLIO_SH) {
      return "./assets/bibliotheken-schaffhausen-logo.svg";
    } else {
      return "./assets/logo.svg";
    }
  }

  get title(): string {
    if (this.whiteLabelVariant == Whitelabel.BIBLIO_SH) {
      return $localize`Bisch KI`;
    } else {
      return $localize`Schaffhauser KI Auskunft`;
    }
  }

  get description(): string {
    if (this.whiteLabelVariant == Whitelabel.BIBLIO_SH) {
      return $localize `Hallo, ich bin eine künstliche Intelligenz (KI), welche an den Katalog der Bibliotheken Schaffhausen angeschlossen ist.`;
    } else {
      return $localize `Hallo, ich bin SHAU. Eine künstliche Intelligenz (KI), welche mit verschiedenen Daten der Stadtverwaltung Schaffhausen trainiert wurde.`;
    }
  }

  get disclaimer(): string {
    if (this.whiteLabelVariant == Whitelabel.SHAU) {
      return $localize `SHAU ist (noch) nicht mit der Stadt Schaffhausen assoziiert`;
    } else {
      return "";
    }
  }

  get suggestedQueries(): string[] {
    let queries: string[] = [];

    if (this.whiteLabelVariant == Whitelabel.BIBLIO_SH) {

      queries.push($localize`Fantasy Romane`)
      queries.push($localize`Sprachförderung Frühbereich`)
      queries.push($localize`Geschichte von Schaffhausen`)
      queries.push($localize`Bücher aus Italien`)
      queries.push($localize`Krimis`)
      //queries.push($localize`Verfügbare Jugendbücher`)
      //queries.push($localize`Öffnungszeiten der Bibliotheken`)

    } else if (this.whiteLabelVariant == Whitelabel.SHAU){
      queries.push($localize`:@@suggest.query.shau.freizeit:Welche Freizeitangebote gibt es?`)
      queries.push($localize`:@@suggest.query.shau.umzug:Was muss ich nach einem Umzug tun?`)
      queries.push($localize`:@@suggest.query.shau.familienangebot:Welche Familienangebote gibt es?`)
      queries.push($localize`:@@suggest.query.shau.abfall:Wie kann ich Abfall entsorgen?`)
      queries.push($localize`:@@suggest.query.shau.plakate:Wo kann ich Plakate aufhängen?`)
    }
    return queries;
  }

  get recentnessRankingRequestedByDefault(): boolean {
    if (this.whiteLabelVariant == Whitelabel.BIBLIO_SH) {
      return false;
    } else {
      return true;
    }
  }

  get sources(): string[] {
    if (this.whiteLabelVariant == Whitelabel.BIBLIO_SH) {
      return [
        "Export_BibSH_29.03.2024_WorldCat.xml",
        "bibliotheken-schaffhausen.ch",
        "hls-dhs-dss.ch",
        "wikipedia"
      ]
    } else if (this.whiteLabelVariant == Whitelabel.SHAU) {
      return [
        "www.stadt-schaffhausen.ch",
        "Amtsblatt",
        "archiv.stsh.ch",
        "www.quartierentwicklung-schaffhausen.ch",
        "www.familienzentrum-schaffhausen.ch",
        "smartcity.stsh.ch",
        "bauprojekte-sh.ch",
        "schaffhauserland.ch",
        "wikipedia",
        "sh.ch",
        "archiv.sh.ch",
        "rechtsbuch.sh.ch",
      ]
    } else {
      return []
    }
  }

  get defaultGPTVersion(): gptVersion {
    if (this.whiteLabelVariant == Whitelabel.BIBLIO_SH) {
      return gptVersion.GPT4o;
    } else {
      return gptVersion.GPT4Turbo;
    }
  }

  setTitleToDisplayForWhitelabel(reference: DocumentReference) {
    if (this.whiteLabelVariant == Whitelabel.BIBLIO_SH) {
      if (reference.title) {
        reference.title_to_display = reference.title;
      }
    } else if (this.whiteLabelVariant == Whitelabel.SHAU) {
      const titleBlacklist = ["verteiler für info-zirkulare, abt. und pers.-komm."]
      if (reference.title && !titleBlacklist.includes(reference.title)) {
        reference.title_to_display = reference.title;
      }
    }
  }

}
