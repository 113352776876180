import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {SourceEntity} from "../../interfaces/sourceEntity";

@Component({
  selector: 'source-entity-filter',
  templateUrl: 'source-entity-filter.component.html',
  styleUrls: ['source-entity-filter.component.scss'],
})
export class SourceEntityFilter implements OnInit{

  allComplete: boolean = true;

  @Input() items: string[] = [];
  @Output() checkboxChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  itemsToSuggest : string[] = this.items.slice(0,5);
  source: SourceEntity ={color: undefined, completed: false, name: ""}
  sourceList: SourceEntity[] = this.items.map(name => ({
    name,
    completed: false,
    color: 'primary'
  }));

  ngOnInit(): void {
    this.itemsToSuggest = this.items.slice(0,5);
    this.sourceList = this.items.map(name => ({
      name,
      completed: false,
      color: 'primary'
    }));
    this.setAll(true);
  }


  updateAllComplete() {
    this.allComplete = this.sourceList != null && this.sourceList.every(t=> t.completed);
    this.items = this.sourceList.filter(t => t.completed).map(source => source.name)
    this.checkboxChange.emit(this.items);
  }

  someComplete(): boolean {
    if (this.sourceList == null) {
      return false;
    }
    return this.sourceList.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.sourceList == null) {
      return;
    }
    this.sourceList.forEach(t => (t.completed = completed));
    this.items = this.sourceList.filter(t => t.completed).map(source => source.name)
    this.checkboxChange.emit(this.items);
  }
}

