<h1 mat-dialog-title class="textcontainer-with-icon">
  <mat-icon>error</mat-icon>
  {{title}}
</h1>
<div mat-dialog-content>
  <p></p>
  {{message}}
  <p *ngIf="detailText" class="detail-text">{{detailText}}</p>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="openFeedbackDialog()" i18n>Feedback geben</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>OK</button>
</mat-dialog-actions>
