import {Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import * as Sentry from "@sentry/browser";


@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  //standalone: true,
  //imports: [MatDialogTitle, MatDialogContent],
})
export class ErrorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  get title(): string {
    return $localize`Fehler`
  }

  get message(): string {
    return this.data.errorMessage ?? $localize`Hoppla, da ist etwas schiefgelaufen. Bitte versuche es später nocheinmal.`
  }

  get detailText(): string | undefined {
    return this.data.error?.message ?? undefined
  }

  openFeedbackDialog() {
    Sentry.showReportDialog()
  }
}

export interface DialogData {
  error: Error | undefined;
  errorType: ErrorType | undefined
  errorMessage: string | undefined
}

export enum ErrorType {
  UNKNOWN_ERROR,
  SERVER_ERROR,
}
