import { Component } from '@angular/core';
import {QuestionService} from "../../services/question.service";

@Component({
  selector: 'privacy-hint-hallucination-disclaimer',
  templateUrl: './privacy-hint-hallucination-disclaimer.component.html',
  styleUrls: ['./privacy-hint-hallucination-disclaimer.component.scss']
})
export class PrivacyHintHallucinationDisclaimerComponent {

  constructor(public questionService: QuestionService) {
  }
}
