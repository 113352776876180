import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import { Language } from './interfaces/languages';
import {WhitelabelService} from "./services/whitelabel.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = this.whitelabelService.title;
  description = this.whitelabelService.description;
  rating: { value: number } = {value: -2};
  iconSource = this.whitelabelService.logoUrl

  setRating(newRating : number){
    this.rating.value = newRating
  }

  mybreakpoint: number;
  constructor(
    private whitelabelService: WhitelabelService,
  ) {
    this.mybreakpoint = (window.innerWidth <= 600) ? 1 : 2;

    const getKeyByValue = (value: string): string | undefined =>
      (Object.keys(Language) as Array<keyof typeof Language>).find(key => Language[key] === value);

    //set language when the site is loaded in case it is not set yet
    const browserLanguage = navigator.language.split('-')[0]
    if (!localStorage.getItem("userLanguage")){
      let pageLanguage = browserLanguage in Object.keys(Language) ? Language[browserLanguage as keyof typeof Language] :  Language.German;
      this.changeLang(pageLanguage)
    }
    let userLanguage : string | null = window.location.pathname.replaceAll("\/", "") || localStorage.getItem("userLanguage") || navigator.language.split('-')[0];
    const keyByValueString = getKeyByValue(userLanguage)
    let pageLanguage: Language = keyByValueString ? Language[keyByValueString as keyof typeof Language] :  Language.German;
    this.changeLang(pageLanguage)

  }
  ngOnInit() {
    this.mybreakpoint = (window.innerWidth <= 600) ? 1 : 2;
  }
  handleSize(event : any) {
    this.mybreakpoint = (event.target.innerWidth <= 600) ? 1 : 2;
  }

  changeLang(userLanguage: Language){
    localStorage.setItem("userLanguage", userLanguage)
  }

  protected readonly Language = Language;
}
