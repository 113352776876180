import {Component, computed, effect, Input} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from '@angular/material/snack-bar';
import {QuestionService} from "../../services/question.service";
import {UserPromptResponse} from "../../interfaces/user-prompt-response";
import {CaptureContext, ScopeContext} from "@sentry/types/types/scope";


@Component({
  selector: 'feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent {

  showAccordion = computed(() => !this.questionService.displayProgressBar() && this.questionService.apiResponseSignal().answer);
  answerHasBeenRated: boolean = false;
  extendedFeedbackWasGiven : boolean = false;

  @Input() apiResponseSignal!: UserPromptResponse;
  @Input() rating!: { value: number };
  @Input() setRating!: (newRating: number) => void;

  email: any;
  feedback: any;
  dropdown: any;
  currentEventId: string = ''
  lastServerRequestUuid = ''
  prompt : string = this.questionService.getUserPrompt()
  answer : string = this.questionService.apiResponseSignal().answer


  feedbackForm: FormGroup; // Define the form

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private questionService: QuestionService,
  ) {
    this.questionService.newQuestion.subscribe(() =>{
      this.resetFeedbackStatus()
    })
    this.feedbackForm = this.fb.group({
      email: ['', [Validators.email]], //optional
      dropdown: [''], // optional
      feedback: ['']// optional
    });

  }

  resetFeedbackStatus(){
    this.answerHasBeenRated = false;
    this.extendedFeedbackWasGiven = false;
    this.lastServerRequestUuid = '';
    this.currentEventId = '';
    this.email = '';
    this.feedback = '';
    this.dropdown = '';
  }

  openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  callSetRating(newRating: number): void {
    this.setRating(newRating)
  }

  sendShortFeedback(rating: number) {
    if (!this.answerHasBeenRated){
      this.callSetRating(rating)
      this.sendFeedback()
    }
    else{
      this.openSnackBar($localize`Für diese Antwort hast du bereits eine Rückmeldung abgegeben.`);
    }
  }

  getCurrentDateTime(): string {
    const now = new Date();

    const day = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const year = now.getFullYear().toString().substr(-2);
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${day}-${month}-${year}-${hours}-${minutes}-${seconds}`;
  }

  sendFeedback() {

    try{
      const serverRequestUuid = this.apiResponseSignal.server_request_uuid ? this.apiResponseSignal.server_request_uuid : '';

      this.prompt = this.questionService.getUserPrompt()
      this.answer = this.questionService.apiResponseSignal().answer

      if (this.lastServerRequestUuid !== serverRequestUuid) {
        let context : CaptureContext = {
          extra:
            {
              "rating": this.rating.value,
              "prompt": this.prompt,
              "answer": this.answer,
              "server_request_uuid" : serverRequestUuid,
              },
          tags: {
            "rating": this.rating.value
          }
        }
        const message = "User Rating"
        console.log("capture event; ", message)

        this.currentEventId = Sentry.captureMessage(message, context)
      }

      this.lastServerRequestUuid = serverRequestUuid
      this.answerHasBeenRated = true;

      let message: string = $localize`Vielen Dank für Deine Rückmeldung!`
      message = this.rating.value === 0 ? message + $localize` Willst Du mir noch mehr Feedback geben? ` : message
      this.openSnackBar(message)

    } catch (error){
      this.openSnackBar($localize`Ups, es ist etwas schief gelaufen. Bitte versuche es später noch einmal.`)
    }

  }

  sendExtendedFeedback() {

    this.email = this.feedbackForm.value.email;
    this.dropdown = this.feedbackForm.value.dropdown;
    this.feedback = this.feedbackForm.value.feedback;

    try{
      const serverRequestUuid = this.apiResponseSignal.server_request_uuid ? this.apiResponseSignal.server_request_uuid : '';

      let context: CaptureContext = {
        extra:
          {
            "eventID": serverRequestUuid,
            "rating": this.rating.value.toString(),
            "prompt": this.prompt,
            "answer": this.answer,
            "feedback_category": this.dropdown,
            "comment": this.feedback,
            "email": this.email
          },
        tags: {
          "rating": this.rating.value,
          "feedback_category": this.dropdown,
        }
      }
      let sentiment = "Extended User Feedback";
      Sentry.captureMessage(sentiment, context);
      this.extendedFeedbackWasGiven = true;
      this.openSnackBar($localize`Vielen Dank für deine erweiterte Rückmeldung!`);
    }

    catch (error){
      this.openSnackBar($localize`Ups, es ist etwas schief gelaufen. Bitte versuche es später noch einmal.`);
    }

  }

}
