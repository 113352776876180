/*
See https://platform.openai.com/docs/models
And check /backend/ai-service/src/model/gpt_version.py
*/

export enum gptVersion {
  GPT4o ="gpt-4o",
  GPT4Turbo = "gpt-4-turbo",
  GPT4 = "gpt-4",
  GPT3point5turbo = "gpt-3.5-turbo",
}
