import {EventEmitter, Injectable, signal, WritableSignal} from '@angular/core';
import {UserPromptResponse} from "../interfaces/user-prompt-response";
import {environment} from 'src/environments/environment';
import {HttpClient} from "@angular/common/http";
import {UserPromptRequest} from "../interfaces/user-prompt-request";
import {ErrorService} from "./error.service";
import * as Sentry from "@sentry/angular-ivy";
@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  apiAnswer: UserPromptResponse = {
    client_request_uuid: "",
    server_request_uuid: "",
    prompt: "",
    answer: "",
    document_references: [],
    search_only: false,
    is_completed: false,
    limit_reached: false
  };

  promptRequestSignal: WritableSignal<UserPromptRequest | undefined> = signal(undefined);
  apiResponseSignal: WritableSignal<UserPromptResponse> = signal(this.apiAnswer);
  displayProgressBar: WritableSignal<boolean> = signal(false);
  newQuestion: EventEmitter<void> = new EventEmitter();
  // prompt: string = ""
  ws?: WebSocket = undefined;

  private backendUrl: string = environment.backendUrl;
  private backendEndpoint: string = this.backendUrl + "/prompt";

  constructor(private httpClient: HttpClient, private errorService: ErrorService) {
    this.reconnectWebSocket()
    //this.displayProgressBar.set(true);

  }

  private reconnectWebSocket() {
    if (this.ws == undefined || this.ws.readyState === this.ws.CLOSED || this.ws.readyState === this.ws.CLOSING) {
      let wsUrl = this.backendUrl.replace("http", "ws")
      if (!wsUrl.startsWith("ws")) {
        let webSocketProtocol = window.location.protocol == 'https:' ? 'wss' : 'ws'
        wsUrl = webSocketProtocol + "://" + window.location.host + wsUrl
      }
      try {
        let ws = new WebSocket(wsUrl + "/ws")
        this.ws = ws;
        ws.onclose = (event) => {
          console.warn("Websocket did disconnect: ", event.reason)
          this.ws = undefined;
        }
      } catch (error) {
        if (error instanceof Error) {
          this.errorService.logError(error)
        }
      }
    }
  }


  async makeServerCall(prompt : UserPromptRequest){
    // let the feedback component know that there was a new question so that it can prepare to take new feedback
    this.newQuestion.emit();

    this.promptRequestSignal.set(prompt);

    //display progress bar while the answer is fetched
    this.displayProgressBar.set(true);

    let message = JSON.stringify(prompt)

    let combinedAnswer: UserPromptResponse = {
      answer: "",
      client_request_uuid: "",
      document_references: [],
      prompt: prompt.user_prompt,
      search_only: prompt.search_only,
      is_completed: false,
      limit_reached: false
    }

    this.reconnectWebSocket()

    // wait for the websocket to be connected
    if (this.ws && this.ws.readyState !== this.ws.OPEN) {
      let awaitConnectStartTime = new Date().valueOf()
      let existingWbeSocket = this.ws;
      let promise = new Promise<void>((resolve, reject) => {
        let timeout = setTimeout(() => {
          resolve();
        }, 5 * 1000)
        existingWbeSocket.onopen = () => {
          clearTimeout(timeout)
          let awaitConnectStopTime = new Date().valueOf()
          let duration = awaitConnectStopTime - awaitConnectStartTime
          console.log("Websocket connected after " + (duration / 1000).toFixed(3) + "s")
          resolve()
        }
      })
      await promise;
    }

    let startTime = new Date().valueOf()
    let duration = -1

    if (this.ws != undefined) {

      let waitForResponseTimeout = setTimeout(() => {
        this.errorService.showError($localize`Leider konnte der Server diese Anfrage aktuell nicht beantworten. Bitte versuche es später noch einmal.`, new Error("Response timeout"))
        this.displayProgressBar.set(false);
        this.resetQuestion();
      }, 60 * 1000)

      this.ws.onmessage = (event) => {
        let responseFromWebsocket
        try {
          responseFromWebsocket = JSON.parse(event.data)
        }
        catch (error){
          console.log("failed to parse: ", event.data)
          console.warn("Error while parsing WebSocket response: " + event.data)
          Sentry.captureException(error, event.data)
          this.errorService.openErrorDialog($localize`Leider konnte die Antwort nicht richtig verarbeitet werden, versuche es noch einmal.`, undefined)
        }
        if (responseFromWebsocket.client_request_uuid === prompt.client_request_uuid) {
          console.log("Received websocket event: ", responseFromWebsocket)

          clearTimeout(waitForResponseTimeout)

          if (responseFromWebsocket.limit_reached) {
            console.warn("Limit reached for request "+responseFromWebsocket.client_request_uuid)
            this.errorService.openErrorDialog($localize`Leider ist ihr Kontingent aktuell ausgeschöpft. Bitte versuche es später noch einmal.`, undefined)
            this.displayProgressBar.set(false);
            this.resetQuestion();

          } else {

            if (responseFromWebsocket.is_completed || responseFromWebsocket.answer.length > 0) {
              if (responseFromWebsocket.is_completed) {
                if (combinedAnswer.answer) {
                  responseFromWebsocket.answer = combinedAnswer.answer
                }
                combinedAnswer = responseFromWebsocket;
                let endTime = new Date().valueOf()
                duration = endTime - startTime
                console.log("Duration for request "+combinedAnswer.client_request_uuid+": ", (duration / 1000).toFixed(3) + "s")
              } else {
                combinedAnswer.answer = combinedAnswer.answer + responseFromWebsocket.answer
              }

              this.apiResponseSignal.set(combinedAnswer); //set signal; this change is detected in the answer-display component
              this.displayProgressBar.set(false); //stop progress bar when answer is fetched
              this.newQuestion.emit(); // let the feedback component know that there was a new question so that it can prepare to take new feedback
            }
          }
        }
      }

      try {
        this.ws.send(message)

      } catch (error) {
        if (error instanceof Error) {
          this.errorService.showError($localize`Leider konnte der Server diese Anfrage aktuell nicht beantworten. Bitte versuche es später noch einmal.`, error)
        }
        this.displayProgressBar.set(false);
        this.resetQuestion();
      }
    } else {
      this.errorService.showError($localize`Leider konnte der Server diese Anfrage aktuell nicht beantworten. Bitte versuche es später noch einmal.`, new Error("Websocket not connected"))
      this.displayProgressBar.set(false);
      this.resetQuestion();
    }
  }

  resetQuestion() {

    if (this.displayProgressBar()) {
      // cannot reset a question while the request is processing
      return
    }

    let emptyResponse: UserPromptResponse = {
      answer: "",
      client_request_uuid: "",
      document_references: [],
      prompt: "",
      search_only: false,
      is_completed: false,
      limit_reached: false
    }
    this.apiResponseSignal.set(emptyResponse); //set signal; this change is detected in the answer-display component
    this.displayProgressBar.set(false); //stop progress bar when answer is fetched
  }

  getUserPrompt() : string {
    let currentPrompt = this.promptRequestSignal();
    return currentPrompt ? currentPrompt.user_prompt : "";
  }
}
