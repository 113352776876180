import {RouterModule, Routes} from '@angular/router';
import { Language } from './interfaces/languages';
import {NgModule} from '@angular/core';

const userLanguageAsString : string = localStorage.getItem("userLanguage") || window.navigator.language.split('/')[0];
const userLanguage: Language = Language[userLanguageAsString as keyof typeof Language] || Language.German

const routes: Routes = [
  {path: '', redirectTo: userLanguage, pathMatch: 'full'}, //
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
