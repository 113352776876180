import { Component } from '@angular/core';

@Component({
  selector: 'fine-print',
  templateUrl: './fine-print.component.html',
  styleUrls: ['./fine-print.component.scss']
})
export class FinePrintComponent {
  panelOpenState = false;

}
